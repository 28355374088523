import { CryptoHelper } from './containers/helper/Crypto/Crypto';
let accessToken = "";
let registrationToken = ''

export const setAccessToken = (s: string) => {
  accessToken = s;
};

export const getAccessToken = () => {
  if(!accessToken){
    accessToken = CryptoHelper.decryption(localStorage.getItem("key")??"") ?? "";
  }
  return accessToken;
};
export const ClearAccessToken = () => {
  accessToken=''
  localStorage.removeItem("key")
 };

export const setRegistrationToken = (s: string) => {
  registrationToken = s
}

export const getRegistrationToken = () => {
  return registrationToken
}

export const clearAllCookies = () => {
  const cookies = document.cookie.split(";");
  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
  });
};

export const getCookie = (name : any) => {
  const cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    const [key, value] = cookie.split("="); 
    if (key === name) {
      return decodeURIComponent(value); 
    }
  }
  return null; 
}